import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  allBalanceLeaveApiTypeName,
  fetchLeaveApiTypesName,
  fetchLeaveHistoryApiTypeName,
  fetchReportersApiTypeName,
  setApplyedFilterData,
  setBalanceLeave,
  setLeaveFilterData,
  setLeaveHistory,
  setLoading,
  setReporters,
  fetchUserRoleDataCreatorTypeName,
  setUserRole,
  setTotalRowCount,
  voidLeaveReasonsTypesName,
  setVoidLeaveReasons,
} from './slice';

import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {leaveSelector} from './selectors';

export function* fetchReportersApiWatcher() {
  yield takeLatest(fetchReportersApiTypeName, fetchReportersHandler);
}

export function* fetchReportersHandler(actions: any): any {
  const {staffPositionId} = actions.payload;
  try {
    yield put(setLoading(true));
    const url = API_PATH.holidayManagement.getSubordinates + staffPositionId;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setReporters(response.data || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}
export function* fetchGetAllBalanceLeaveApiWatcher() {
  yield takeLatest(allBalanceLeaveApiTypeName, fetchAllBalanceLeaveHandler);
}

export function* fetchAllBalanceLeaveHandler(actions: any): any {
  const {c_year, ssStaffPositionId} = actions.payload;
  try {
    yield put(setLoading(true));
    const url =
      API_PATH.holidayManagement.getBalanceLeaveByUserId +
      ssStaffPositionId +
      '&year=' +
      c_year;
    const response = yield call(NetworkService.post, url, {}, {});
    yield put(setBalanceLeave(response?.data || {}));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}

export function* fetchLeaveHistoryWatcher() {
  yield takeLatest(fetchLeaveHistoryApiTypeName, fetchLeaveHistoryListHandler);
}
const determineStatus = async (userStatusList: any) => {
  let hasZero = false;
  let hasOne = false;
  for (let item of userStatusList) {
    if (item.userStatus === 0) {
      hasZero = true;
    } else if (item.userStatus === 1) {
      hasOne = true;
    }
  }
  if (hasZero && hasOne) {
    return 2;
  } else if (hasZero) {
    return 0;
  } else {
    return 1;
  }
};
export function* fetchLeaveHistoryListHandler(actions: any): any {
  const {c_year, ssStaffPositionId, pageSize, pageNo} = actions.payload;
  try {
    yield put(setLoading(true));
    const url = API_PATH.holidayManagement.getAdminLeaveList;
    const leaveFiltersPayload = yield select(
      leaveSelector.getApplyedFilterDataSelector(),
    );

    if (Object.keys(leaveFiltersPayload)?.length === 0) {
      return;
    }
    const userStatus = yield determineStatus(
      leaveFiltersPayload.userStatusList,
    );
    const payload = {
      ...leaveFiltersPayload,
      isActive: userStatus,
      userId: ssStaffPositionId,
      year: c_year,
      pageSize: pageSize,
      pageNo: pageNo,
    };
    const response = yield call(NetworkService.post, url, payload, {});
    yield put(setLeaveHistory(response?.data?.data || []));
    yield put(setTotalRowCount(response?.data?.leaveCount || 0));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchReportersHandler:', error);
  }
}

export function* fetchUserRoleWatcher() {
  yield takeLatest(fetchUserRoleDataCreatorTypeName, fetchUserRoleDataWorker);
}
export function* fetchUserRoleDataWorker(actions: any): any {
  try {
    yield put(setLoading(true));
    const url = `${API_PATH.holidayManagement.userRoles}`;
    const rolesDropDown = yield call(NetworkService.get, url, {}, {});
    const userRole = rolesDropDown?.data?.map((item: any) => {
      return {
        value: item.value,
        label: item.label,
      };
    });
    yield put(setUserRole(userRole || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchUserRoleDataWorker:', error);
  }
}
export function* fetchLeaveTypeWatcher() {
  yield takeLatest(fetchLeaveApiTypesName, fetchLeaveTypeListHandler);
}
/**
 * Handler for fetching leave type list.
 * Fetches all leave filter options and updates Redux state.
 */
export function* fetchLeaveTypeListHandler(): any {
  try {
    yield put(setLoading(true));
    const fetchData = async (url: string, params: any = {}) => {
      const res = await NetworkService.get(url, params, {});
      return res.status === 200 ? res.data : [];
    };
    /**
     * Fetches the list of leave types along with their IDs.
     * Filters out 'UNPAID LEAVE' entries.
     * @returns Array of leave type objects with 'value' and 'id' properties.
     */
    const fetchLeaveTypes = async () => {
      const url = API_PATH.holidayManagement.allLeaveType;
      const res = await fetchData(url);
      return (
        res
          // .filter((item: any) => item.name.toLowerCase() !== 'unpaid leave')
          .map((value: any) => ({
            value: value.name,
            id: value.id,
          }))
      );
    };
    /**
     * Fetches the list of countries and their states.
     * If India is found, fetches the states for India.
     * @returns Object containing country and state filter data.
     */
    const fetchCountryAndStates = async () => {
      const countryRes = await fetchData(
        API_PATH.holidayManagement.getcountrylist,
      );
      const indiaCountry = countryRes.find(
        (value: any) => value?.countryName.toLowerCase() === 'india',
      );
      if (indiaCountry) {
        const stateRes = await fetchData(
          API_PATH.holidayManagement.getStateListByCountryID,
          {CountryId: indiaCountry.id},
        );
        const stateArr = stateRes
          .map((value: any) => ({
            value: value.stateName,
            id: value.id,
          }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value));
        return {
          Country: {
            filterData: [
              {
                value: indiaCountry.countryName,
                id: indiaCountry.id,
                isChecked: true,
                isDisable: true,
              },
            ],
            collapsed: true,
            isChecked: true,
            isDisable: true,
          },
          State: {
            filterData: stateArr,
            collapsed: true,
            isChecked: false,
          },
        };
      }
      return {};
    };
    /**
     * Fetches the list of divisions.
     * @returns Object containing division filter data.
     */
    const fetchDivision = async () => {
      const url = API_PATH.holidayManagement.division;
      const divisionArr = await fetchData(url);
      return {
        Division: {
          filterData: divisionArr
            .map((value: any) => ({
              value: value.name,
              id: value.id,
            }))
            .sort((a: any, b: any) => a.value.localeCompare(b.value)),
          collapsed: true,
          isChecked: false,
        },
      };
    };
    /**
     * Fetches all options for leave filters by calling individual fetch functions.
     * @returns Object containing all leave filter options.
     */
    const fetchOptions = async () => {
      const leaveTypes = await fetchLeaveTypes();
      const countryAndStates = await fetchCountryAndStates();
      const division = await fetchDivision();

      return {
        'Approval status': {
          filterData: [
            {value: 'Pending', id: 1, isChecked: true},
            {value: 'Approved', id: 2},
            {value: 'Rejected', id: 3},
            {value: 'Void', id: 4},
          ],
          collapsed: true,
          isChecked: false,
        },
        'Leave Type': {
          filterData: leaveTypes,
          collapsed: true,
          isChecked: false,
        },
        ...countryAndStates,
        ...division,
        'User Status': {
          filterData: [
            {value: 'Active', id: 1, isChecked: true},
            {value: 'InActive', id: 0},
          ],
          collapsed: true,
          isChecked: false,
        },
      };
    };

    const option = yield call(fetchOptions);
    yield put(setLeaveFilterData(option));
    const defaultFilter = {
      statusList: [{statusId: 1}], // Pending
      leaveTypeList: [],
      stateList: [],
      divisionList: [],
      userStatusList: [
        {
          userStatus: 1, //for active
        },
      ],
    };
    yield put(setApplyedFilterData(defaultFilter));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchLeaveTypeListHandler:', error);
  }
}

export function* fetchVoidLeaveReasonsWatcher() {
  yield takeLatest(voidLeaveReasonsTypesName, fetchVoidLeaveReasonsWorker);
}
export function* fetchVoidLeaveReasonsWorker(): any {
  try {
    yield put(setLoading(true));
    const url = API_PATH.holidayManagement.getAdminVoidLeaveReasons;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setVoidLeaveReasons(response.data));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
  }
}
